.addCouponForm {
  .formFiledBorder {
    border: 1px solid #fd82059e;
    padding: 15px;
    border-radius: 8px;
  }

  .swithBtn {
    border: 5px solid #fd8205;
    border-radius: 50px;

    .switch {
      border-radius: 50px;
      border: none;
    }

    .switch-group {
      width: 172%;
    }

    .switch-on {
      background: #fd8205;
      color: #fff;
      font-weight: 500;
    }

    .switch-off {
      font-weight: 500;
      background: #1db4e9;
      color: #fff;
    }

    .switch.off {
      .switch-group {
        left: -72%;
      }
    }

    .switch-handle {
      width: 36px;
      border-width: 0 1px;
      background-color: #202124;
      border: none;
      border-radius: 50px;
    }
  }

  .form-control,
  .form-select {
    &:focus {
      outline: none !important;
    }

    box-shadow: none !important;
    font-weight: 300;
    font-size: 16px;
    background-color: #fd8205 !important;
    color: #ffffff;
    border: none;
    padding: 10px;

    &::placeholder {
      color: #ffffff;
    }
  }

  p.text-danger {
    margin-bottom: 0;
    margin-top: 5px;
    color: #ff5d5d !important;
  }

  // .planSelectDetails {
  // }
  .submitBtn {
    background: #fd8205;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    padding: 8px 30px;
  }

  .cardInput {
    #field-wrapper {
      padding: 15px 10px;
      background: #fd8205;

      .credit-card-input {
        background: #fd8205;
        color: #fff;

        &::placeholder {
          color: #ffffff;
        }
      }
    }

    .sc-bcXHqe,
    .sc-beqWaB,
    .sc-jTrPJq,
    .sc-iVCKna {
      width: 100%;
    }
  }

  .apply-coupon-btn {
    background: #dd6f00;
    color: #fff;
  }

  .packagePrice {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dd6f00;
    font-size: 20px;
    padding: 0 15px;
    font-weight: 600;
    border-radius: 0 5px 5px 0;
  }

  .priceDetails {
    border: 1px solid #fd82059e;
    padding: 15px;
    border-radius: 8px;
  }
}
