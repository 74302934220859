.successModal {
  .modal-content {
    background-color: antiquewhite;
    .successGif {
      //   margin: 0 auto;
      // padding-bottom: 25px;
      margin: 0 auto;
      /* padding-bottom: 52px; */
      width: 50%;
      border: 1px solid #ffc06da6;
      border-radius: 100%;
      margin-bottom: 20px;
      width: 50%;
      img {
        width: 100%;
        // border: 2px solid #ffc06d;
        // border-radius: 100%;
        // height: 325px;
      }
    }
    .redirect-alert {
      backdrop-filter: blur(6px);
      background-color: #ffe5c1a1;
      border: 1px dashed #ff92355c;
      color: #324148;
      font-weight: 500;
    }
  }
}
