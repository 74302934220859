.alert-bg {
  position: relative;
  background: #e41749;
  margin: 0 auto;
  width: 560px;
  max-width: 90%;
  border-radius: 12px;
  padding: 16px 22px 17px 20px;
  display: flex;
  align-items: center;
  .close_btn {
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    top: 15px;
    right: 15px;
    color: #fff;
    cursor: pointer;
  }
  .icon__wrapper {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.253);
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      font-size: 21px;
      color: #fff;
    }
  }
  p {
    margin: 0 8px;
    color: #fff;
  }
}
